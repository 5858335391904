
import { Provide } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import quotes from '@/services/quotes'
import customer from '@/services/customer'
import { pluralize } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Quote, QuoteTableViewResult } from '@/models/dto/Quote'
import user from '@/services/user'
import auth from '@/store/modules/auth'
import quoteStore from '@/store/modules/quote'
import ContactSidebarDetail from '@/components/ContactSidebarDetail.vue'
import CompanySidebarDetail from '@/components/CompanySidebarDetail.vue'
import column from '@/store/modules/columns'
import QuotesListQuoteId from '@/components/QuotesListQuoteId.vue'
import ListTags from './ListTags.vue'
import { Status } from '@/models/dto/Status'
import { Priority } from '@/models/dto/Priority'
import QuoteSetPrioritySidebar from './QuoteSetPrioritySidebar.vue'
import QuoteSetStatusSidebar from './QuoteSetStatusSidebar.vue'
import HoldUpModal from './HoldUpModal.vue'
import CustomEmailSidebar from './CustomEmailSidebar.vue'
import { Email } from '@/models/dto/Email'
import { baseUrl } from '@/utils/env'
import { EmailTemplate } from '@/models/dto/Template'
import { Customer } from '@/models/dto/Customer'
import { SourceCategory } from '@/utils/enum'
import { subject, body } from '@/data/quoteTemplate'
import { validate } from '@/utils/quote'
import QuoteSummarySidebarDetailExpanded from '@/components/QuoteSummarySidebarDetailExpanded.vue'
import terms from '@/services/terms'
import { filter } from '@/utils/filter'
import { columns, initialSavedViews } from '@/data/quoteTableView'
import { SavedView } from '@/models/dto/SavedView'

@Component({
  components: {
    CUCollectionTable,
    QuotesListQuoteId,
    ListTags,
    HoldUpModal,
  },
  metaInfo: { title: 'Quotes' },
})
export default class QuotesList extends mixins(DateMixin) {
  @Provide() limitTableToViewHeight = true

  async created(): Promise<void> {
    const [sentBy, createdBy] = await Promise.all([
      user.usersWhoHaveSentQuotes(),
      user.usersWhoHaveCreatedQuotes(),
    ])
    this.columns = columns(
      this.formatLongDateLongTime,
      sentBy.data.users,
      createdBy.data.users
    )
    this.loading = false
  }

  mounted(): void {
    EventBus.$on('quotes:open-booking-contact', this.openBookingContact)
    EventBus.$on('quotes:open-billing-contact', this.openBillingContact)
    EventBus.$on('quotes:open-company', this.openCompany)
    EventBus.$on('quotes:set-status', this.handleSetQuoteStatus)
    EventBus.$on('quotes:set-priority', this.handleSetQuotePriority)
    EventBus.$on('quotes:delete', this.handleDeleteQuotes)
    EventBus.$on('quotes:send', this.handleSendQuotesToCustomer)
    EventBus.$on('quotes:duplicate', this.handleDuplicateQuote)
    EventBus.$on('quote:openQuoteSummarySidebar', this.openQuoteSummarySidebar)
  }

  beforeDestroy(): void {
    EventBus.$off('quotes:open-booking-contact', this.openBookingContact)
    EventBus.$off('quotes:open-billing-contact', this.openBillingContact)
    EventBus.$off('quotes:open-company', this.openCompany)
    EventBus.$off('quotes:set-status', this.handleSetQuoteStatus)
    EventBus.$off('quotes:set-priority', this.handleSetQuotePriority)
    EventBus.$off('quotes:delete', this.handleDeleteQuotes)
    EventBus.$off('quotes:send', this.handleSendQuotesToCustomer)
    EventBus.$off('quotes:duplicate', this.handleDuplicateQuote)
    EventBus.$off('quote:openQuoteSummarySidebar', this.openQuoteSummarySidebar)
  }

  tableView = quotes.tableView
  initialSavedViews: SavedView[] = initialSavedViews
  columns: DataTableColumn[] = []

  count = 0
  quoteOwners = []
  selectedQuotes: Quote[]
  isDeleteQuoteModalOpen = false
  selectedQuotesCount = null
  contacts: Customer[] = []
  loading = true

  getExpirationCutoffDate(): string {
    return new Date(
      new Date().toLocaleString('en-US', { timeZone: auth.getCompanyTimeZone })
    )
      .toISOString()
      .substring(0, 10)
  }

  get canExportQuotes(): boolean {
    return !!auth.roles.find((role) => role.roleName === 'can_export_quotes')
  }

  downloadQuotesList(): any {
    column.setIsDownloadEnabled(this.canExportQuotes)
    if (!this.canExportQuotes) {
      return null
    }
    return quotes.export
  }

  handleDeleteQuotes(quotes: Quote[]): void {
    this.selectedQuotesCount = quotes.length
    this.selectedQuotes = quotes
    this.isDeleteQuoteModalOpen = true
  }

  openBookingContact(row: Quote): void {
    sidebar.popAllAndPush({
      component: ContactSidebarDetail,
      props: {
        userId: row.customerId,
        simple: true,
        contactType: 'Booking',
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  openBillingContact(row: Quote): void {
    sidebar.popAllAndPush({
      component: ContactSidebarDetail,
      props: {
        userId: row.billingCustomerId,
        simple: true,
        contactType: 'Billing',
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  openCompany(row: QuoteTableViewResult): void {
    const customerAccountId =
      row.customerAccountId || row.customer?.customerAccountId
    sidebar.popAllAndPush({
      component: CompanySidebarDetail,
      props: { customerAccountId },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  handleAddQuote(quote: Quote): void {
    if (quote) {
      const route = this.$router.resolve({
        name: 'quotes.duplicate',
        params: { id: 'new', data: quote.quoteId.toString() },
      })
      window.open(route.href, `Busify Quote Duplicate ${quote.quoteId}`)
    } else {
      this.$router.push({
        name: 'quotes.detail',
        params: { id: 'new' },
      })
    }
  }

  async handleSendQuotesToCustomer(selectedQuotes: Quote[]): Promise<void> {
    const quote = selectedQuotes[0]
    const defaultTemplate = new EmailTemplate({
      subject: subject.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      opener: body.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      includePdf: true,
    })

    try {
      let customerPromise = null
      let billingCustomerPromise = null

      if (quote.customerId) {
        customerPromise = customer.byId(quote.customerId)
      }

      if (quote.billingCustomerId) {
        billingCustomerPromise = customer.byId(quote.billingCustomerId)
      }

      const [
        bookingContactResponse,
        billingContactResponse,
        quoteTemplatesResponse,
        fullQuote,
        companyHasTerms,
      ] = await Promise.all([
        customerPromise,
        billingCustomerPromise,
        quotes.getEmailTemplate(),
        quotes.byId(quote.quoteId),
        this.getDefaultPaymentTerms(),
      ])

      const quoteTemplates = quoteTemplatesResponse.data.emailCustomizationDTO
        ? [quoteTemplatesResponse.data.emailCustomizationDTO, defaultTemplate]
        : [defaultTemplate]

      if (!validate(fullQuote?.data?.quote) || !companyHasTerms) {
        EventBus.$emit(
          'snackbar:error',
          'This quote is missing some required information, please review and try again.'
        )
        return
      }

      quoteStore.setQuote(fullQuote.data.quote)

      sidebar.popAllAndPush({
        component: CustomEmailSidebar,
        props: {
          id: quote.managedId,
          link: `https://${baseUrl()}/checkout/${quote.hash}`,
          pdfType: 'Quote',
          title: `Quote ${quote.managedId}`,
          isQuote: true,
          templates: quoteTemplates,
          useAlternateSender: true,
          bookingContact: bookingContactResponse?.data?.customer,
          billingContact: billingContactResponse?.data?.customer,
        },
        width: 500,
        on: {
          send: (email: Email) => this.sendQuoteEmail(email, quote.quoteId),
        },
      })
    } catch (e) {
      EventBus.$emit(
        'snackbar:error',
        'Unable to load all required quote information, please try again.'
      )
      console.error(e)
    }
  }

  async sendQuoteEmail(email: Email, quoteId: number): Promise<void> {
    try {
      await quotes.sendQuoteEmail({
        timezone: auth.getUserTimeZone,
        quoteId,
        ...email,
      })
      EventBus.$emit('snackbar:success', `Email sent successfully!`)
      EventBus.$emit('refresh-tableview')
    } catch (e) {
      EventBus.$emit('snackbar:error', 'Error sending email.')
    } finally {
      sidebar.pop()
    }
  }

  handleDuplicateQuote(selectedQuotes: Quote[]): void {
    if (selectedQuotes.length !== 1) {
      return
    }
    this.handleAddQuote(selectedQuotes[0])
  }

  async deleteQuotes(): Promise<void> {
    if (this.selectedQuotesCount === 0) {
      this.isDeleteQuoteModalOpen = false
      return
    }
    await quotes.bulkDelete(this.selectedQuotes.map((q) => q.quoteId))
    EventBus.$emit('refresh-tableview')
    EventBus.$emit(
      'snackbar:success',
      `${pluralize(this.selectedQuotesCount, 'Quote')} deleted successfully!`
    )
  }

  async setQuoteOwners(): Promise<void> {
    const quoteOwners = await user.getQuoteOwners({ pageSize: -1 })
    if (!quoteOwners?.data?.resultList) {
      return
    }
    this.quoteOwners = quoteOwners.data.resultList.map((user) => ({
      ...user,
      text: `${user.firstName} ${user.lastName}`,
      value: `${user.firstName} ${user.lastName}`,
    }))
  }

  async getDefaultPaymentTerms(): Promise<boolean> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: '780c574b-6256-453b-bbda-4befde5eacbc',
        value: 'isDefault',
        filterAsIs: true,
        filterProp: 'managedId',
        filterType: 'eq',
      },
      value: true,
    })
    const params = {
      page: 1,
      pageSize: 1,
      filters: filters.asQueryParams(),
      sorts: undefined,
    }
    const defaultTerms = await terms.tableView(params)

    return defaultTerms.data.count > 0
  }

  handleSetQuoteStatus(quotes: Quote[]): void {
    this.selectedQuotes = quotes

    sidebar.popAllAndPush({
      component: QuoteSetStatusSidebar,
      title: 'Set Quote Status',
      on: { save: (status: Status) => this.setQuoteStatus(status) },
    })
  }

  async setQuoteStatus(status: Status): Promise<void> {
    if (!status) {
      return
    }

    try {
      const quoteIds = this.selectedQuotes.map((q) => q.quoteId)
      await quotes.updateQuoteStatuses(quoteIds, status.id)
      EventBus.$emit(
        'snackbar:success',
        `${pluralize(
          this.selectedQuotes.length,
          'Quote'
        )} updated successfully!`
      )
      EventBus.$emit('refresh-tableview')
    } catch (error) {
      EventBus.$emit('snackbar:error', 'Error bulk updating quotes')
    } finally {
      sidebar.pop()
    }
  }

  handleSetQuotePriority(quotes: Quote[]): void {
    this.selectedQuotes = quotes

    sidebar.popAllAndPush({
      component: QuoteSetPrioritySidebar,
      title: 'Set Quote Priority',
      on: { save: (priority: Priority) => this.setQuotePriority(priority) },
    })
  }

  async setQuotePriority(priority: Priority): Promise<void> {
    if (!priority) {
      return
    }

    try {
      const quoteIds = this.selectedQuotes.map((q) => q.quoteId)
      await quotes.updateQuotePriorities(quoteIds, priority.id)
      EventBus.$emit(
        'snackbar:success',
        `${pluralize(
          this.selectedQuotes.length,
          'Quote'
        )} updated successfully!`
      )
      EventBus.$emit('refresh-tableview')
    } catch (error) {
      EventBus.$emit('snackbar:error', 'Error bulk updating quotes')
    } finally {
      sidebar.pop()
    }
  }

  isCharterUpReferral(quote: Quote): boolean {
    return !!quote && quote?.sourceCategory === SourceCategory.REFERRAL
  }

  areReferralsSelected(quotes: Quote[] = []): boolean {
    return quotes
      .map(this.isCharterUpReferral)
      .reduce((acc, isReferral) => acc || isReferral, false)
  }

  areConvertedQuotesSelected(quotes: Quote[] = []): boolean {
    const CONVERTED_QUOTE_STATUS_ID = 3
    return !!quotes.find(
      (quote) => quote.quoteStatusId === CONVERTED_QUOTE_STATUS_ID
    )
  }

  referralTooltip(quotes: Quote[] = []): string {
    return (
      this.areReferralsSelected(quotes) && 'Action not available for Referrals'
    )
  }

  convertedTooltip(quotes: Quote[] = []): string {
    return (
      this.areConvertedQuotesSelected(quotes) &&
      'Action not available for converted quotes'
    )
  }

  sendToCustomerTooltip(quotes: Quote[] = []): string {
    if (quotes?.length > 1) {
      return 'This action cannot be done in bulk'
    }

    return this.referralTooltip(quotes) || this.convertedTooltip(quotes)
  }

  async openQuoteSummarySidebar(quote: Quote): Promise<void> {
    const fullQuote = await quotes.byId(quote.quoteId)
    quoteStore.setQuote(fullQuote.data.quote)
    sidebar.popAllAndPush({
      title: `Quote ${quote.managedId}`,
      component: QuoteSummarySidebarDetailExpanded,
      props: {
        actions: [],
        actionEvents: {},
        quoteId: quote.quoteId,
      },
      width: 1479,
      wide: false,
      on: {
        close: () => {
          quoteStore.setQuote(null)
        },
      },
    })
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: (rows: Quote[]): void => {
        EventBus.$emit('quotes:delete', rows)
      },
    },
    {
      displayText: 'Duplicate Quote',
      key: 'duplicated',
      icon: 'duplicate',
      iconWidth: '16px',
      iconHeight: '16px',
      tooltip: this.referralTooltip,
      disabled: this.areReferralsSelected,
      isSingleSelectOnly: true,
      action: (rows: Quote[]): void => {
        EventBus.$emit('quotes:duplicate', rows)
      },
    },
    {
      displayText: 'Set Status',
      key: 'set-status',
      icon: 'edit',
      tooltip: 'Status cannot be changed for Converted quotes',
      disabled: this.areConvertedQuotesSelected,
      action: (rows: Quote[]): void => {
        EventBus.$emit('quotes:set-status', rows)
      },
    },
    {
      displayText: 'Set Priority',
      key: 'set-priority',
      icon: 'edit',
      action: (rows: Quote[]): void => {
        EventBus.$emit('quotes:set-priority', rows)
      },
    },
    {
      displayText: 'Send to Customer',
      key: 'send',
      icon: 'send',
      tooltip: this.sendToCustomerTooltip,
      disabled: (quotes: Quote[] = []): boolean => {
        return (
          this.areReferralsSelected(quotes) ||
          this.areConvertedQuotesSelected(quotes)
        )
      },
      isSingleSelectOnly: true,
      action: (rows: Quote[]): void => {
        EventBus.$emit('quotes:send', rows)
      },
    },
  ]
}
