
import { Vue, Component } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { Status } from '@/models/dto/Status'
import { quoteStatusTypes } from '@/data/types'

@Component({})
export default class QuoteSetStatusSidebar extends Vue {
  status: Status = null
  statuses: Status[] = quoteStatusTypes.filter((s) => s.key !== 'converted')
  loading = false

  clear(): void {
    this.loading = false
    sidebar.pop()
  }

  submit(): void {
    this.$emit('save', this.status)
    this.loading = true
  }
}
