
import { Vue, Component } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import tclient from '@/services/type'
import { Priority } from '@/models/dto/Priority'

@Component({})
export default class QuoteSetPrioritySidebar extends Vue {
  priority: Priority = null
  priorities: Priority[] = []
  loading = false

  clear(): void {
    this.loading = false
    sidebar.pop()
  }

  submit(): void {
    this.$emit('save', this.priority)
    this.loading = true
  }

  async load(): Promise<void> {
    const res = await tclient.priorityTypes()
    this.priorities = res.data
  }

  mounted(): void {
    this.load()
  }
}
