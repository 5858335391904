
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import { QuotePriorityLabels } from '@/utils/enum'
@Component({
  components: { CUDataTableLink },
})
export default class QuotesListPriority extends Vue {
  @Prop({ required: false, default: QuotePriorityLabels.NONE })
  readonly priority!: QuotePriorityLabels

  labels = QuotePriorityLabels

  priorityIconMap = {
    [QuotePriorityLabels.LOW]: 'arrow_down',
    [QuotePriorityLabels.MEDIUM]: 'bar',
    [QuotePriorityLabels.HIGH]: 'arrow_up',
  }

  priorityIconLabels = {
    [QuotePriorityLabels.LOW]: 'Low Priority',
    [QuotePriorityLabels.MEDIUM]: 'Medium Priority',
    [QuotePriorityLabels.HIGH]: 'High Priority',
  }
}
