
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import { EventBus } from '@/utils/eventBus'
import { Quote } from '@/models/dto/Quote'

@Component({
  components: { CUDataTableLink },
})
export default class QuotesListQuoteId extends Vue {
  @Prop({ type: Boolean, default: false }) displayUnread!: boolean
  @Prop({ required: true }) readonly activeQuote: Quote

  hovering = false

  openSidebar(): void {
    EventBus.$emit('quote:openQuoteSummarySidebar', this.activeQuote)
  }
}
