import { DataTableColumn } from '@/models/DataTableColumn'
import {
  countryPhoneFormatFilter,
  currencyFilter,
  formatFullName,
} from '@/utils/string'
import QuotesListQuoteStatus from '@/components/QuotesListQuoteStatus.vue'
import { Quote, QuoteTableViewResult } from '@/models/dto/Quote'
import { RawLocation } from 'vue-router'
import auth from '@/store/modules/auth'
import QuotesListQuoteId from '@/components/QuotesListQuoteId.vue'
import QuotesListReservationIds from '@/components/QuotesListReservationIds.vue'
import ListTags from '@/components/ListTags.vue'
import QuotesListPriority from '@/components/QuotesListPriority.vue'
import dayjs from 'dayjs'
import QuotesListExpirationDate from '@/components/QuotesListExpirationDate.vue'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { predefinedFutureDate } from '@/utils/datePicker'
import { Status } from '@/models/dto/Status'
import { quotePriorityTypes, quoteStatusTypes } from '@/data/types'
import { DateOptions } from '@/models/Date'
import { UserDetail } from '@/models/dto'

const CAN_VIEW_CONTACTS = auth.getUserRoleNames.includes(
  ACCESS_SETTINGS_ROLES.CONTACTS
)

const getQuoteStatus = (row: Quote): Status => {
  const status: Status = quoteStatusTypes.find(
    (status) => status.id === row.quoteStatusId
  )
  return status || quoteStatusTypes[0]
}

const expirationStatusItems = (): {
  text: string
  value: string
  filterType: string
}[] => {
  const date = new Date(
    new Date().toLocaleString('en-US', { timeZone: auth.getCompanyTimeZone })
  )
    .toISOString()
    .substring(0, 10)
  return [
    {
      text: 'Expired',
      value: date,
      filterType: 'lte',
    },
    {
      text: 'Not Expired',
      value: `${date} `,
      filterType: 'gte',
    },
  ]
}

const userOptionsCreatedBy = (
  createdBy: UserDetail[]
): { text: string; value: string }[] => {
  const options = []
  if (auth.getShowQuoteWidgetOnCustomersFilter) {
    options.push({
      text: 'Quote Widget',
      value: 'Quote Widget',
    })
  }
  return options.concat(
    ...(createdBy || [])
      .map((user) => ({
        text: formatFullName(user),
        value: formatFullName(user),
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  )
}

const userOptionsSentBy = (
  sentBy: UserDetail[]
): { text: string; value: string }[] => {
  return (sentBy || [])
    .map((user) => ({
      text: formatFullName(user),
      value: formatFullName(user),
    }))
    .sort((a, b) => a.text.localeCompare(b.text))
}

export const columns = (
  formatLongDateLongTime: (date: string, opts: DateOptions) => string,
  sentBy: UserDetail[],
  createdBy: UserDetail[],
): DataTableColumn[] => {
  let columns = [
    {
      _t_id: '702155d5-cda5-401f-8df1-61293be9f63b',
      text: 'Quote ID',
      value: 'managedId',
      type: 'slot',
      linkLocation: (row: Quote): RawLocation => ({
        name: 'quotes.detail',
        params: { id: String(row.quoteId) },
      }),
      linkToNewTab: true,
      columnSlotProps: (row: Quote): Record<string, boolean | Quote> => ({
        displayUnread: row.managedId === '523530', // Hard-coded until back-end split
        activeQuote: row,
      }),
      filterProp: 'managedId',
      filterBySearch: true,
      columnSlotComponent: QuotesListQuoteId,
      hidden: false,
      required: true,
    },
    {
      _t_id: '01a50620-b67a-40d3-ba9c-736a4b8b8651',
      text: 'Quote Status',
      value: 'quoteStatus',
      type: 'slot',
      columnSlotComponent: QuotesListQuoteStatus,
      columnSlotProps: (row: Quote) => ({
        status: getQuoteStatus(row),
        expirationDate: row.expirationDate,
      }),
      hidden: false,
      sortable: false,
      filterable: true,
      filterProp: 'quoteStatus/id',
      filterInterface: 'multi-select',
      filterDropdownItems: quoteStatusTypes.map((status) => ({
        text: status.label,
        value: String(status.id),
      })),
      filterHiddenOnTopBar: false,
      label: 'quoteStatusName',
      unsetWidthOnBreakpoint: 'lgAndUp',
      filterType: 'eq',
      filterValue: [],
    },
    {
      _t_id: 'c0f0d298-87d9-4b1e-98a0-30e7d638344b',
      text: 'Priority',
      value: 'quotePriorityName',
      type: 'slot',
      columnSlotComponent: QuotesListPriority,
      columnSlotProps: (row: Quote) => ({ priority: row.quotePriorityName }),
      hidden: false,
      sortable: false,
      filterable: true,
      filterProp: 'quotePriority/id',
      filterInterface: 'multi-select',
      filterDropdownItems: quotePriorityTypes.map((status) => ({
        text: status.label,
        value: String(status.id),
      })),
      filterHiddenOnTopBar: false,
      unsetWidthOnBreakpoint: 'lgAndUp',
      filterType: 'eq',
      filterValue: [],
    },
    {
      _t_id: '65769663-2544-4224-996e-0bb2e9264773',
      text: 'Company',
      value: 'customerAccountName',
      computedText: (row): string =>
        row.customerAccountId ? row.customerAccountName : '',
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      filterBySearch: true,
      filterProp: 'customerAccountName',
      clickEvent: 'quotes:open-company',
      hidden: false,
      unsetWidthOnBreakpoint: 'lgAndUp',
    },
    {
      _t_id: '9cc03d96-6ded-448a-ba2c-f72e4a21d76a',
      text: 'Booking Contact',
      value: 'customerName',
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      clickEvent: 'quotes:open-booking-contact',
      filterProp: 'customerName',
      filterBySearch: true,
      hidden: false,
      unsetWidthOnBreakpoint: 'lgAndUp',
    },
    {
      _t_id: '46b6e035-f195-482f-b4d8-2ef4ce8462a4',
      text: 'Billing Contact',
      value: 'billingCustomerName',
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      clickEvent: 'quotes:open-billing-contact',
      filterProp: 'billingCustomerName',
      filterBySearch: true,
      hidden: true,
      unsetWidthOnBreakpoint: 'lgAndUp',
    },
    {
      _t_id: '22230f9c-1cd5-43f3-be0f-8a1d4546dc65',
      text: 'Pickup Date',
      value: 'pickupDate',
      computedText: (row): string =>
        formatLongDateLongTime(row.pickupDate, {
          tz: row.firstPickupAddressTimezone,
          showMeridianUpper: true,
          showTimezone: true,
          showDot: true,
        }),
      filterable: true,
      filterProp: 'pickupDate',
      filterInterface: 'date-range',
      filterHiddenOnTopBar: false,
      hidden: false,
    },
    {
      _t_id: '48a18c4b-91d1-401b-bea0-fadc918aa07f',
      text: 'Total',
      value: 'amount',
      computedText: (row): string => currencyFilter(row.amount),
      hidden: false,
    },
    {
      _t_id: '8f81d29c-8220-4469-9439-af4f16b46b1f',
      text: 'Base Fare',
      value: 'baseFare',
      computedText: (row): string => currencyFilter(row.baseFare),
      hidden: true,
    },
    {
      _t_id: '57f15322-b842-4a9b-87df-a248234d1cd5',
      text: 'Created',
      value: 'createdOn',
      computedText: (row): string =>
        formatLongDateLongTime(row.createdOn, {
          tz: auth.getUserTimeZone,
          showMeridianUpper: true,
          showTimezone: true,
          showDot: true,
        }),
      filterable: true,
      filterProp: 'createdOn',
      filterInterface: 'date-range',
      filterHiddenOnTopBar: true,
      hidden: false,
    },
    {
      _t_id: 'efe98c71-0474-466c-a0b8-4bfab1b9e554',
      text: 'Created By',
      value: 'createdByName',
      filterable: true,
      filterProp: 'createdByName',
      filterBySearch: true,
      filterHiddenOnTopBar: true,
      hidden: false,
      unsetWidthOnBreakpoint: 'lgAndUp',
      filterInterface: 'multi-select',
      filterDropdownItems: userOptionsCreatedBy(createdBy),
      filterType: 'eq',
      filterValue: [],
    },
    {
      _t_id: 'df1ade4b-fe9c-459e-84ef-067c2617551a',
      text: 'Referred By',
      value: 'referredBy',
      filterProp: 'referredBy',
      filterBySearch: true,
      hidden: true,
      required: false,
    },
    {
      _t_id: '6ff2d488-05b8-47e0-98f3-b02ac4bce5eb',
      text: 'Referral ID',
      value: 'managedReferralId',
      filterProp: 'managedReferralId',
      filterBySearch: true,
      hidden: true,
      required: false,
    },
    {
      _t_id: '7eb436c6-a8d8-4195-a56c-8fb9df524807',
      text: 'Reservation IDs',
      value: 'reservationIds',
      type: 'slot',
      columnSlotProps: (
        row: QuoteTableViewResult
      ): Record<string, unknown> => ({
        reservations: row.reservations,
        quoteId: row.quoteId,
      }),
      columnSlotComponent: QuotesListReservationIds,
      hidden: true,
      required: false,
    },
    {
      _t_id: '19f82a0f-f0e4-4173-a0b1-0d1dac332ced',
      text: 'Converted On',
      value: 'convertedOn',
      computedText: (row): string =>
        formatLongDateLongTime(row.convertedOn, {
          tz: auth.getUserTimeZone,
          showMeridianUpper: true,
          showTimezone: true,
          showDot: true,
        }),
      filterable: true,
      filterProp: 'convertedOn',
      filterInterface: 'date-range',
      filterHiddenOnTopBar: true,
      hidden: true,
    },
    {
      _t_id: 'f5a5b1e6-d899-4e85-a273-7df10a2a600a',
      text: 'First Pickup Address',
      value: 'firstPickupAddress',
      hidden: true,
      type: 'text',
      filterable: true,
      filterBySearch: true,
      filterProp: 'firstPickupAddress',
      filterType: 'contains',
    },
    {
      _t_id: 'a0dc87fd-6c88-4722-9a07-88163a06421c',
      text: 'First Drop-off Address',
      value: 'firstDropoffAddress',
      hidden: true,
      type: 'text',
      filterable: true,
      filterBySearch: true,
      filterProp: 'firstDropoffAddress',
      filterType: 'contains',
    },
    {
      _t_id: '557d2632-27d6-4f49-ba39-50b2a9048928',
      text: 'First Drop-off Date',
      value: 'dropoffDate',
      computedText: (row): string =>
        formatLongDateLongTime(row.dropoffDate, {
          tz: row.firstDropoffAddressTimezone,
          showMeridianUpper: true,
          showTimezone: true,
          showDot: true,
        }),
      filterable: true,
      filterProp: 'dropoffDate',
      filterInterface: 'date-range',
      filterHiddenOnTopBar: true,
      hidden: true,
    },
    {
      _t_id: '59145a8d-e6d7-4e61-ba51-a493b75da0e2',
      text: 'Trip Types',
      value: 'tripTypes',
      computedText: (row): string =>
        row.tripTypes === 'None' ? '--' : row.tripTypes,
      hidden: true,
      type: 'text',
      filterable: true,
      filterBySearch: true,
      filterProp: 'tripTypes',
      filterType: 'contains',
    },
    {
      _t_id: 'a665c274-5382-44f2-b316-9fea6efc3867',
      text: 'Vehicle Types',
      value: 'vehicleTypes',
      hidden: true,
      type: 'text',
      filterable: true,
      filterBySearch: true,
      filterProp: 'vehicleTypes',
      filterType: 'contains',
    },
    {
      _t_id: 'b82079ff-0670-4f9c-a3e7-4626f06bb92c',
      text: 'Sent By',
      value: 'sentByName',
      hidden: true,
      filterBySearch: true,
      type: 'text',
      filterable: true,
      filterProp: 'sentByName',
      filterHiddenOnTopBar: true,
      filterInterface: 'multi-select',
      filterDropdownItems: userOptionsSentBy(sentBy),
      unsetWidthOnBreakpoint: 'lgAndUp',
      filterType: 'eq',
      filterValue: [],
    },
    {
      _t_id: 'efb47699-c69b-4278-a0fb-e9ed84315a7e',
      text: 'Tags',
      value: 'tagsString',
      type: 'slot',
      columnSlotProps: (
        row: QuoteTableViewResult
      ): Record<string, unknown> => ({
        tagsString: row.tagsString,
      }),
      columnSlotComponent: ListTags,
      filterProp: 'tagsString',
      filterBySearch: true,
      hidden: true,
    },
    {
      _t_id: '5dd66849-7350-412e-a89c-375b35e31cca',
      text: 'Group',
      value: 'customerAccountGroupName',
      hidden: true,
      type: 'text',
      filterable: true,
      filterBySearch: true,
      filterProp: 'customerAccountGroupName',
      filterType: 'contains',
    },
    {
      _t_id: '6ff2d488-05b8-47e0-98f3-b02ac4bce6ab',
      text: 'Event Type',
      value: 'eventTypeName',
      filterable: true,
      filterBySearch: true,
      filterProp: 'eventTypeName',
      filterType: 'contains',
      hidden: true,
      required: false,
      computedText: (row): string => row.eventTypeName || '--',
    },
    {
      _t_id: '566aa04f-f677-4a35-8be0-016f039bbde0',
      text: 'Expiration Date',
      value: 'expirationDate',
      type: 'slot',
      columnSlotComponent: QuotesListExpirationDate,
      columnSlotProps: (row: Quote) => ({
        expirationDate: row.expirationDate,
      }),
      filterable: true,
      filterProp: 'expirationDate',
      filterInterface: 'date-range',
      filterHiddenOnTopBar: true,
      hidden: true,
    },
    {
      _t_id: '70f53cb5-4492-4585-8180-0ccce76651ae',
      text: 'Expiration Status',
      type: 'text',
      invisible: true,
      value: 'expirationDate',
      filterable: true,
      filterProp: 'expirationDate',
      filterInterface: 'select',
      filterDropdownItems: expirationStatusItems(),
      filterType: 'eq',
      filterValue: -1,
      filterHiddenOnTopBar: true,
      hidden: true,
    },
    {
      _t_id: '19b082f6-c5e8-4a87-af02-3b53470288a2',
      text: 'Booking Contact Phone',
      value: 'phone',
      hidden: true,
      sortable: false,
      computedText: (row): string => {
        const { customerPhoneExtension, customerPhoneCountryKey } = row
        let phone = countryPhoneFormatFilter(
          row.customerPhone,
          customerPhoneCountryKey
        )
        if (customerPhoneExtension) {
          phone = `${phone} ext. ${
            customerPhoneExtension.length > 5
              ? `${customerPhoneExtension.slice(0, 5)}...`
              : customerPhoneExtension
          }`
        }
        return phone
      },
    },
  ]

  if (
    auth?.getUserRoleNames != null &&
    !auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.RESERVATIONS)
  ) {
    const RESERVATION_COLUMN_ID = '7eb436c6-a8d8-4195-a56c-8fb9df524807'

    columns = columns.filter((column) => column._t_id !== RESERVATION_COLUMN_ID)
  }

  return columns as DataTableColumn[]
}

export const initialSavedViews = [
  {
    name: 'Leads',
    savedViewId: -1,
    default: true,
    viewSettings: {
      pinned: true,
      orderIndex: 2,
      pageSize: 25,
      columns: [
        {
          label: 'Quote ID',
          _t_id: '702155d5-cda5-401f-8df1-61293be9f63b',
          hidden: false,
        },
        {
          label: 'Quote Status',
          _t_id: '01a50620-b67a-40d3-ba9c-736a4b8b8651',
          hidden: false,
          filterValue: ['1', '2'],
          filterType: 'eq',
        },
        {
          label: 'Priority',
          _t_id: 'c0f0d298-87d9-4b1e-98a0-30e7d638344b',
          hidden: false,
          filterValue: [],
        },
        {
          label: 'Company',
          _t_id: '65769663-2544-4224-996e-0bb2e9264773',
          hidden: false,
        },
        {
          label: 'Booking Contact',
          _t_id: '9cc03d96-6ded-448a-ba2c-f72e4a21d76a',
          hidden: false,
        },
        {
          label: 'Event Type',
          _t_id: '6ff2d488-05b8-47e0-98f3-b02ac4bce6ab',
          hidden: false,
        },
        {
          label: 'Pickup Date',
          _t_id: '22230f9c-1cd5-43f3-be0f-8a1d4546dc65',
          hidden: false,
          filterValue: [dayjs().format('YYYY-MM-DD'), predefinedFutureDate],
        },
        {
          label: 'First Pickup Address',
          _t_id: 'f5a5b1e6-d899-4e85-a273-7df10a2a600a',
          hidden: false,
        },
        {
          label: 'Total',
          _t_id: '48a18c4b-91d1-401b-bea0-fadc918aa07f',
          hidden: false,
        },
        {
          label: 'Created',
          _t_id: '57f15322-b842-4a9b-87df-a248234d1cd5',
          hidden: false,
        },
        {
          label: 'Expiration Date',
          _t_id: '566aa04f-f677-4a35-8be0-016f039bbde0',
          hidden: false,
        },
        {
          label: 'Created By',
          _t_id: 'efe98c71-0474-466c-a0b8-4bfab1b9e554',
          hidden: false,
        },
      ],
    },
  },
  {
    name: 'All Quotes',
    savedViewId: 0,
    default: false,
    viewSettings: {
      pinned: true,
      orderIndex: 0,
      pageSize: 25,
      columns: [
        {
          label: 'Quote ID',
          _t_id: '702155d5-cda5-401f-8df1-61293be9f63b',
          hidden: false,
        },
        {
          label: 'Quote Status',
          _t_id: '01a50620-b67a-40d3-ba9c-736a4b8b8651',
          hidden: false,
        },
        {
          _t_id: 'c0f0d298-87d9-4b1e-98a0-30e7d638344b',
          label: 'Priority',
          hidden: false,
        },
        {
          label: 'Company/Customer Account Name',
          _t_id: '65769663-2544-4224-996e-0bb2e9264773',
          hidden: false,
        },
        {
          label: 'Booking Contact',
          _t_id: '9cc03d96-6ded-448a-ba2c-f72e4a21d76a',
          hidden: false,
        },
        {
          label: 'Total/Amount',
          _t_id: '48a18c4b-91d1-401b-bea0-fadc918aa07f',
          hidden: false,
        },
        {
          label: 'Created',
          _t_id: '57f15322-b842-4a9b-87df-a248234d1cd5',
          hidden: false,
        },
        {
          label: 'Pickup Date',
          _t_id: '22230f9c-1cd5-43f3-be0f-8a1d4546dc65',
          hidden: false,
          filterValue: [],
        },
        {
          label: 'Dropoff Date',
          _t_id: '58e44db1-6321-46fb-9308-fa5bd6363a9c',
          hidden: false,
        },
        // POST LAUNCH
        // {
        //   label: 'Quote Owner',
        //   _t_id: 'efe98c71-0474-466c-a0b8-4bfab1b9e554',
        //   hidden: false,
        //   filterValue: -1,
        //   filterType: 'contains',
        // },
      ],
    },
  },
  // POST LAUNCH
  // {
  //   name: 'My Quotes',
  //   _id: -2,
  //   viewSettings: {
  //     pinned: true,
  //     orderIndex: 1,
  //     columns: [
  //       {
  //         label: 'Quote ID',
  //         _t_id: '702155d5-cda5-401f-8df1-61293be9f63b',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Quote Status',
  //         _t_id: '01a50620-b67a-40d3-ba9c-736a4b8b8651',
  //         hidden: false,
  //       },
  //       {
  //         _t_id: 'c0f0d298-87d9-4b1e-98a0-30e7d638344b',
  //         label: 'Priority',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Company/Customer Account Name',
  //         _t_id: '65769663-2544-4224-996e-0bb2e9264773',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Booking Contact',
  //         _t_id: '9cc03d96-6ded-448a-ba2c-f72e4a21d76a',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Total/Amount',
  //         _t_id: '48a18c4b-91d1-401b-bea0-fadc918aa07f',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Created',
  //         _t_id: '57f15322-b842-4a9b-87df-a248234d1cd5',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Pickup Date',
  //         _t_id: '22230f9c-1cd5-43f3-be0f-8a1d4546dc65',
  //         hidden: false,
  //         filterValue: [],
  //       },
  //       {
  //         label: 'Dropoff Date',
  //         _t_id: '58e44db1-6321-46fb-9308-fa5bd6363a9c',
  //         hidden: false,
  //       },
  //       {
  //         label: 'Quote Owner',
  //         _t_id: 'efe98c71-0474-466c-a0b8-4bfab1b9e554',
  //         hidden: false,
  //         filterValue: `${auth.user.firstName} ${auth.user.lastName}`,
  //         filterType: 'contains',
  //       },
  //     ],
  //   },
  // },
]
