
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Status } from '@/models/dto/Status'
import dayjs from 'dayjs'
import { QuoteStatusTypeId } from '@/utils/enum'

@Component({})
export default class QuotesListQuoteStatus extends Vue {
  @Prop({ required: true }) readonly status!: Status
  @Prop({ required: true }) readonly expirationDate!: string

  get borderColor(): string {
    return Status.color(this.status)?.foreground
  }

  get bgColor(): string {
    return Status.color(this.status)?.background
  }

  get tag(): string {
    return this.status?.label || ''
  }

  get isExpired(): boolean {
    if (!this.expirationDate) {
      return false
    }
    return (
      this.status.id === QuoteStatusTypeId.Sent &&
      dayjs().isAfter(this.expirationDate.split('+')[0])
    )
  }
}
