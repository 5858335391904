
import auth from '@/store/modules/auth'
import { timeZones } from '@/utils/time'
import dayjs from 'dayjs'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'

@Component
export default class QuotesListExpirationDate extends mixins(DateMixin) {
  @Prop({ required: false, default: null })
  readonly expirationDate!: string

  get formattedDate(): string {
    if (!this.expirationDate) {
      return ''
    }
    const timezone =
      timeZones.find(
        (t) => t.zoneName === auth.getCompany?.address?.timeZone
      ) || timeZones[0]

    return this.formatLongDateLongTime(this.expirationDate.split('+')[0], {
      tz: timezone.zoneName,
      showMeridianUpper: true,
      showDot: true,
      showTimezone: true,
    })
  }

  get isExpired(): boolean {
    if (!this.expirationDate) {
      return false
    }
    return dayjs().isAfter(this.expirationDate.split('+')[0])
  }
}
